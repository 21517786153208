import styled, { css } from 'styled-components';

export const ErrorMesaggeWrapper = styled.div`
    display: flex;
    algin-items: center;
`;

export const ErrorText = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        padding-left: 4px;
        color: ${colors.error};
        font-size: ${fontSizes.f12};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.m};
        line-height: 20px;
    `,
);
