import styled, { css } from 'styled-components';

export const Label = styled.label(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            padding-bottom: 8px;
            color: ${colors.neutral900};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.bold};
            letter-spacing: ${letterSpacing.m};
            line-height: 18px;
        `,
);

export const TextArea = styled.textarea<{
    isError?: boolean;
    inputIsWhite?: boolean;
}>(
    ({
        theme: { colors, fontSizes, fontWeight, letterSpacing },
        isError,
        inputIsWhite,
    }) => css`
        background: ${inputIsWhite ? colors.white : colors.neutral50};
        border: 1px solid ${isError ? colors.error : colors.neutral200};
        caret-color: ${colors.primaryBlue500};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.s};
        line-height: 28px;
        margin-bottom: ${isError ? '6px' : '0'};
        outline: none;
        padding: 12px 16px;
        resize: vertical;
        width: 100%;
        &::placeholder {
            color: ${colors.neutral400};
        }
        ::-ms-input-placeholder {
            color: ${colors.neutral400};
        }
        &:active,
        &:focus {
            border-color: ${isError ? colors.error : colors.primaryBlue500};
        }
    `,
);

export const TextareaWrapper = styled.div<{ motherPage?: boolean }>(
    ({ motherPage, theme: { fontSizes, fontWeight } }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ${motherPage &&
        css`
            ${Label} {
                font-size: ${fontSizes.f16};
            }

            ${TextArea} {
                font-size: ${fontSizes.f18};
                font-weight: ${fontWeight.regular};
            }
        `}
    `,
);
