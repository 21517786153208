import React from 'react';
import { Label, Input, InputWrapper } from './TextInput.styled';
import { ErrorMesagge } from 'components/ErrorMesagge/ErrorMesagge';
import {
    Control,
    FieldError,
    FieldErrors,
    useController,
} from 'react-hook-form';

interface TextInputProps {
    control: Control;
    errors?: FieldError | FieldErrors;
    inputIsWhite?: boolean;
    label?: string;
    motherPage?: boolean;
    name: string;
    placeholder?: string;
    type: 'text' | 'email';
}

export const TextInput = ({
    control,
    errors,
    inputIsWhite,
    label,
    motherPage,
    name,
    placeholder,
    type,
}: TextInputProps) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    return (
        <InputWrapper motherPage={motherPage}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <Input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder && placeholder}
                value={value}
                onChange={onChange}
                isError={!!errors}
                inputIsWhite={inputIsWhite}
            />
            {errors && <ErrorMesagge error={errors} />}
        </InputWrapper>
    );
};
