import React from 'react';
import { Label, TextArea, TextareaWrapper } from './Textarea.styled';
import { ErrorMesagge } from 'components/ErrorMesagge/ErrorMesagge';
import { Control, FieldError, useController } from 'react-hook-form';

interface TextareaProps {
    control: Control;
    errors?: FieldError;
    inputIsWhite?: boolean;
    label?: string;
    maxLength?: number;
    motherPage?: boolean;
    name: string;
    placeholder?: string;
}

export const Textarea = ({
    control,
    errors,
    inputIsWhite,
    label,
    maxLength,
    motherPage,
    name,
    placeholder,
}: TextareaProps) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });
    return (
        <TextareaWrapper motherPage={motherPage}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <TextArea
                id={name}
                inputIsWhite={inputIsWhite}
                maxLength={maxLength ?? 300}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            ></TextArea>
            {errors && <ErrorMesagge error={errors} />}
        </TextareaWrapper>
    );
};
