import React from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';
import { ErrorIcon } from 'shared/svg/error';
import { ErrorText, ErrorMesaggeWrapper } from './ErrorMesagge.styled';

interface ErrorMesaggeProps {
    error?: FieldError | FieldErrors;
}

export const ErrorMesagge = ({ error }: ErrorMesaggeProps) => {
    return (
        <ErrorMesaggeWrapper>
            <ErrorIcon width="20" height="20" />
            <ErrorText> {error?.message}</ErrorText>
        </ErrorMesaggeWrapper>
    );
};
